<template>
  <div class="scroll-container payment-due-date">
    <CategoryTitle :category="category" />

    <!-- 
      <v-row class="no-border">
        <v-col cols="12" class="pb-0" style="display: flex;">
          <v-col cols="4" class="pb-0">
          <v-checkbox
            class="w-100 mt-0"
            @click="changeFilter()"
            v-model="dataFilter.codInt"
            :label="`${$t('profile.clientCode')}`"
          ></v-checkbox>
        </v-col>

        <v-col cols="4" class="pb-0">
          <v-checkbox
            class="w-100 mt-0"
            @change="changeFilter()"
            v-model="dataFilter.piva"
            :label="`${$t('paymentInvoice.vatCode')}`"
          ></v-checkbox>
        </v-col>
      </v-col>
    </v-row> -->

    <v-row class="no-border" v-if="enableFilter">
      <h4 class="secondary--text">
        Dati filtrati per:
      </h4>
      <br />
      <v-col cols="12" class="pb-0">
        <v-radio-group
          v-model="dataFilter.currentValue"
          row
          class="w-100 mt-0"
          @change="changeFilter"
          :mandatory="false"
        >
          <v-radio
            color="default"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
            :value="'codInt'"
            style="min-width: 120px;"
            ><template v-slot:label>
              <span class="ml-2 text-h5 font-weight-bold">
                <h5>
                  {{ $t("profile.clientCode") }}
                </h5>
              </span>
            </template></v-radio
          >
          <v-radio
            color="default"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
            :value="'piva'"
            ><template v-slot:label>
              <span class="ml-2 text-h5 font-weight-bold">
                <h5>
                  {{ $t("paymentInvoice.vatCode") }}
                </h5>
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <br />

    <div class="invoice-list pt-1" v-if="list.length > 0">
      <v-row class="header payment-table">
        <v-col cols="2" md="3"
          ><strong>{{ $t("paymentDueDate.state") }}</strong></v-col
        >
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"
          ><strong>{{ $t("paymentDueDate.docDate") }}</strong></v-col
        >
        <v-col cols="3" md="1"><strong>N&ordm;</strong></v-col>
        <v-col cols="2" align="end"
          ><strong>{{ $t("paymentDueDate.amount") }}</strong></v-col
        >
        <v-col cols="2" align="end"
          ><strong>{{ $t("paymentDueDate.weld") }}</strong></v-col
        >
        <v-col cols="2"
          ><strong>{{ $t("paymentDueDate.expiration") }}</strong></v-col
        >
      </v-row>
      <v-row
        v-for="item in list"
        :key="item.paymentDueDateId"
        class="payment-table"
      >
        <v-col cols="2" md="3"
          ><v-checkbox
            class="mt-0"
            :input-value="item.selected"
            @click.prevent="update(item)"
            hide-details
            :disabled="!item.paymentDueDateStatusId == 1 || !item.payable"
          >
            <template v-slot:label>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-chip
                  v-if="item.busy"
                  small
                  :class="[
                    'payment-duedate-status-' + item.statusID + '-' + item.busy
                  ]"
                >
                  {{ $t(`paymentDueDate.status[${100 + item.statusID}]`) }}
                </v-chip>

                <v-chip
                  v-else
                  small
                  :class="['payment-duedate-status-' + item.statusID]"
                >
                  {{ $t(`paymentDueDate.status[${item.statusID}]`) }}
                </v-chip>

                <span v-if="item.paymentDueDateStatusId > 1">
                  Tran. n: {{ item.orderTransactionId }}
                </span>
              </div>
              <div v-else>
                <!-- Content to be shown on screens smaller than md -->
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2">{{
          $dayjs(item.docDate).format("DD-MM-YYYY")
        }}</v-col>
        <v-col cols="3" md="1">{{ item.docReferenceNumber }}</v-col>
        <v-col cols="2" align="end">{{ $n(item.docAmount, "currency") }}</v-col>
        <v-col cols="2" align="end"
          ><strong v-if="item.paymentDueDateStatusId == 1">{{
            $n(item.paymentAmount, "currency")
          }}</strong></v-col
        >
        <v-col cols="2">{{ $dayjs(item.dueDate).format("DD-MM-YYYY") }}</v-col>
      </v-row>
      <v-row class="total-pay payment-table">
        <v-col cols="6" align="end"
          ><strong>{{ $t("paymentDueDate.totalToPay") }}:</strong></v-col
        >
        <v-col cols="6">{{ $n(total, "currency") }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6" align="end"
          ><strong>{{ $t("paymentDueDate.selectedToPay") }}:</strong></v-col
        >
        <v-col cols="6">{{ $n(totalSelected, "currency") }}</v-col>
      </v-row>
      <div class="pt-4" v-if="totalSelected > 0">
        <h4 class="secondary--text">
          {{ $t("paymentDueDate.paySelected") }}
        </h4>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-btn color="secondary" outlined block @click="pay(1)">
              {{ $t("paymentDueDate.payWithCard") }}
            </v-btn></v-col
          >
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-btn color="secondary" outlined block @click="pay(9)">
              {{ $t("paymentDueDate.payByTransfer") }}
            </v-btn></v-col
          >
        </v-row>
      </div>
    </div>
    <v-card light outlined v-else class="pa-2 text-center w-100">
      <v-card-title class="headline">{{
        $t("paymentDueDate.noAvailablePayments")
      }}</v-card-title>
    </v-card>
    <div class="pt-4" v-if="transactions.length > 0">
      <h4 class="secondary--text">
        {{ $t("paymentDueDate.listOfTransactions") }}
      </h4>
      <v-row class="header payment-table">
        <v-col cols="2"><strong>N&ordm;</strong></v-col>
        <v-col cols="2"
          ><strong>{{ $t("paymentDueDate.transactions.date") }}</strong></v-col
        >
        <v-col cols="3"
          ><strong>{{ $t("paymentDueDate.transactions.state") }}</strong></v-col
        >
        <v-col cols="2"
          ><strong>{{
            $t("paymentDueDate.transactions.payment")
          }}</strong></v-col
        >
        <v-col cols="3" align="end"
          ><strong>{{
            $t("paymentDueDate.transactions.amount")
          }}</strong></v-col
        >
      </v-row>
      <v-row
        class="payment-table"
        v-for="t in transactions"
        :key="'t_' + t.orderTransactionId"
      >
        <v-col cols="2"
          ><strong>{{ t.orderTransactionId }}</strong></v-col
        >
        <v-col cols="2">{{ t.addDate }}</v-col>
        <v-col cols="3"
          ><v-chip
            small
            :class="['transaction-status-' + t.transactionStatusId]"
            >{{
              $t(
                `paymentDueDate.transactions.transactionStatus[${t.transactionStatusId}]`
              )
            }}</v-chip
          ></v-col
        >
        <v-col cols="2">{{
          $t(`paymentDueDate.transactions.paymentType[${t.paymentTypeId}]`)
        }}</v-col>
        <v-col cols="3" align="end"
          ><strong>{{ $n(t.authorizedAmount, "currency") }}</strong></v-col
        >
      </v-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment-due-date {
  .payment-table + .row:nth-child(even) {
    background-color: var(--v-grey-lighten1);
  }
  .total-pay {
    border-top: 2px solid $secondary;
  }
  .payment-duedate-status-1 {
    background-color: #ffb3ba;
  }
  .payment-duedate-status-101 {
    background-color: rgb(194, 250, 231);
  }
  .payment-duedate-status-2-true {
    background-color: rgb(194, 250, 231);
  }
  .payment-duedate-status-2 {
    background-color: #ffffba;
  }
  .payment-duedate-status-3 {
    background-color: #ffffba;
  }
  .payment-duedate-status-103 {
    background-color: #ffffba;
  }
  .payment-duedate-status-4 {
    background-color: #baffc9;
  }
  .payment-duedate-status-104 {
    background-color: #baffc9;
  }
  .payment-duedate-status-0 {
    background-color: #bae1ff;
  }
  .payment-duedate-status-100 {
    background-color: #bae1ff;
  }
  .transaction-status-0 {
    background-color: #ffb3ba;
  }
  .transaction-status-1 {
    background-color: #bae1ff;
  }
  .transaction-status-2 {
    background-color: #baffc9;
  }
  .transaction-status-3 {
    background-color: #baffc9;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import reload from "~/mixins/reload";
import login from "~/mixins/login";
import categoryMixin from "~/mixins/category";

import CustomService from "@/service/customService";
import PaymentDueDatesService from "@/commons/service/paymentDueDatesService";

export default {
  name: "PaymentDueDate",
  data() {
    return {
      list: [],
      transactions: [],
      total: 0,
      totalSelected: 0,
      dataFilter: { currentValue: "codInt", codInt: true, piva: false },
      enableFilter: false
    };
  },
  mixins: [reload, login, categoryMixin],
  components: { CategoryTitle },

  computed: {},
  methods: {
    changeFilter(data) {
      if (data == "piva") {
        this.dataFilter.piva = true;
      } else {
        this.dataFilter.piva = false;
      }
      this.dataFilter.codInt = !this.dataFilter.piva;
      this.loadPaymentDueDateList();
    },

    async getEnableFilter() {
      await CustomService.getEnableFilter().then(data => {
        this.enableFilter = data.value;
        if (data.value && data.value.enableFilter != undefined) {
          this.enableFilter = data.value.enableFilter;
          if (!this.enableFilter) {
            this.dataFilter.piva = true;
            this.dataFilter.codInt = true;
          }
        } else {
          this.enableFilter = false;
          this.dataFilter.piva = true;
          this.dataFilter.codInt = true;
        }
      });
    },

    loadPaymentDueDateList() {
      CustomService.getPaymentDueDateList(
        this.dataFilter.piva,
        this.dataFilter.codInt
      ).then(data => {
        this.list = data.paymentDueDates;
        this.modifyList();
      });
    },

    reload() {
      this.loadPaymentDueDateList();
      CustomService.getTransactions().then(data => {
        this.transactions = data.orderTransaction;
      });
    },
    modifyList() {
      this.total = 0;
      this.totalSelected = 0;
      this.list.forEach(i => {
        i.selected = false;
        i.statusID = 0;
        if (i.paymentDueDateStatusId == 1) {
          this.total += i.paymentAmount;
          if (this.$dayjs(i.dueDate).isBefore(this.$dayjs()) && i.payable) {
            i.selected = true;
            this.totalSelected += i.paymentAmount;
            i.statusID = 1;
          } else {
            i.statusID = 2;
          }
        } else if (i.paymentDueDateStatusId == 2) {
          i.statusID = 2;
        } else if (i.paymentDueDateStatusId == 3) {
          i.statusID = 4;
        }
      });
    },
    calculateTotal() {
      this.totalSelected = 0;
      this.list.forEach(i => {
        if (i.selected && i.paymentDueDateStatusId == 1) {
          this.totalSelected += i.paymentAmount;
        }
      });
    },
    update(item) {
      let index = this.list.findIndex(
        i => i.paymentDueDateId === item.paymentDueDateId
      );
      this.list[index].selected = !this.list[index].selected;
      this.calculateTotal();
    },
    pay(paytype) {
      let selected = [];
      let payments = [paytype];
      var _this = this;
      this.list.forEach(i => {
        if (i.selected && i.paymentDueDateStatusId == 1) {
          selected.push(i.paymentDueDateId);
        }
      });
      PaymentDueDatesService.duedatePay(selected, payments)
        .then(data => {
          if (
            _this.$platform_is_cordova &&
            data.data.paymentUrl.indexOf("http") > -1
          ) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.data.paymentUrl, "_system");
          } else {
            window.open(data.data.paymentUrl, "_blank");
          }
        })
        .catch(error => {
          global.EventBus.$emit("error", {
            error: error,
            message: "ERROR"
          });
        })
        .finally(() => this.reload());
    }
  },
  async created() {
    await this.getEnableFilter();
    this.reload();
  }
};
</script>
